import React from 'react';
import "./Footer.css";
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">

        </div>
  )
}

export default Footer